require('./index.scss');

// Custom jQuery plugin for initializing the expandable.
(function ($) {
    // Detects touch devices:
    // https://stackoverflow.com/a/63666289/10197327
    const touch = matchMedia('(hover: none)').matches;

    $.fn.oiExpandable = function () {
        // "this" is the wrapper. Initializing every item below it.
        this.find('.oi-expandable-item').each(function () {
            const $item = $(this);

            const $title = $item.find('.oi-expandable-title');

            const backgroundUrl = $item.attr('data-oi-expandable-background');
            const iconUrl = $item.attr('data-oi-expandable-icon');

            // Setting the background with a gradient.
            if (backgroundUrl) {
                $item.css({
                    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 65.03%), url(' + backgroundUrl + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                });
            }

            // If an icon is defined, setting it above the title.
            if (iconUrl) {
                const $iconDiv = $('<div class="oi-expandable-icon" aria-hidden="true"/>');

                $iconDiv.css({
                    background: 'url(' + iconUrl + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                });

                $iconDiv.insertBefore($title);
            }

            if (!touch) {
                // Adding the proper class on interaction.
                $item.on('mouseover', function () {
                    console.log('mouseover');
                    expand($item, false);
                    // Doing nothing in responsive mode.
                });
                $item.on('click', function(e) {
                    console.log('click');
                    if ($item.parent().css('flex-direction') === 'row' && !$item.hasClass('expanded')) {
                        expand($item, true);
                        e.preventDefault();
                        return false;
                    }
                })
            }

            function expand($item, checkClick) {
                console.log('expand');
                if ($item.parent().css('flex-direction') === 'row') {
                    if (!$item.hasClass('expanded') && (checkClick || !$item.hasClass('shrunk'))) {
                        // All items in the same row must be shrunk.
                        $('.oi-expandable-item').removeClass('expanded');
                        $('.oi-expandable-item').removeClass('shrunk');
                        $item.siblings().addClass('shrunk');
                        $item.addClass('expanded');
                    }
                }
            }
        });

        if (!touch) {
            // Putting a listener on the body, to close expanded
            // elements when another spot is clicked.
            $('body').on('click', function (event) {
                // Checking if the target is inside the expanded
                // element. If not, closing every expanded element.
                if ($(event.target).closest('.oi-expandable-item.expanded').length === 0) {
                    $('.oi-expandable-item.expanded, .oi-expandable-item.shrunk')
                        .each(function () {
                            $(this).removeClass('expanded');
                            $(this).removeClass('shrunk');
                        });
                }
            });
        }
    }
}(jQuery));

// When the document loads, initializing every existing expandable.
$(document).ready(function () {
    console.log('oi-expandable');
    $('.oi-expandable-wrapper').oiExpandable();
});
